import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormApi } from '../../api/FormApi';
import { TFunction } from '../../types/types';
import { createFormBiLogger, FormBiLogger } from '../bi/biLoggerFactory';
import { VisitorLogger } from '@wix/yoshi-flow-editor/external-types/bi';
import { FormState } from '../state/initialStateFactory';

export type FormContext = {
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
  biLogger: FormBiLogger;
};

export const createFormContext = ({
  t,
  settings,
  wixSdkAdapter,
  formApi,
  biLogger,
}: {
  t: TFunction;
  settings: ControllerFlowAPI['settings'];
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
  biLogger: FormBiLogger;
}): FormContext => {
  return {
    t,
    settings,
    wixSdkAdapter,
    formApi,
    biLogger,
  };
};
