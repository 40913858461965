import { BookingsQueryParams } from '@wix/bookings-adapter-ooi-wix-sdk';
import { FormState } from '../state/initialStateFactory';
import { widgetDefaults } from './consts';
import { VisitorLogger } from '@wix/yoshi-flow-editor';

export interface FormBiLogger extends VisitorLogger {
  update: (state: FormState) => void;
}

export function createFormBiLogger({
  viewerBiLogger,
  initialState,
  wixSdkAdapter,
}: {
  viewerBiLogger: VisitorLogger;
  initialState: FormState;
  wixSdkAdapter: any;
}): FormBiLogger {
  const mapStateToDefaultBiParams = (state: FormState) => {
    return {};
  };

  const updateDefaultBiParams = (state: FormState) => {
    viewerBiLogger.updateDefaults(mapStateToDefaultBiParams(state));
  };

  viewerBiLogger?.updateDefaults?.({
    ...widgetDefaults,
    referralInfo: wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.REFERRAL,
    ),
    ...mapStateToDefaultBiParams(initialState),
  });

  return Object.assign(viewerBiLogger, {
    update: updateDefaultBiParams,
  }) as FormBiLogger;
}
