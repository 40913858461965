import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import {
  createInitialState,
  FormState,
} from '../../utils/state/initialStateFactory';
import { createControlledComponent } from '../../utils/ControlledComponent/ControlledComponent';
import {
  createFormContext,
  FormContext,
} from '../../utils/context/contextFactory';
import { createFormActions, FormControllerActions } from './Actions/actions';
import { createWixSdkAdapter } from '../../utils/sdkAdapterFactory';
import { FormApi } from '../../api/FormApi';
import { mapConfigToState } from '../../utils/dummies/editor-behaviour';
import { ITEM_TYPES } from '@wix/advanced-seo-utils/api';
import { getSeoItemData } from '../../utils/seo/seo';
import { createFormBiLogger } from '../../utils/bi/biLoggerFactory';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  let rerender: (
    propsToUpdate: Partial<FormState | { actions: FormControllerActions }>,
  ) => void = () => {};
  return {
    async pageReady() {
      const {
        controllerConfig,
        translations: { t },
        settings,
      } = flowAPI;

      const wixSdkAdapter = createWixSdkAdapter(controllerConfig);
      const formApi = new FormApi({ wixSdkAdapter });
      const initialState: FormState = await createInitialState({
        wixSdkAdapter,
        flowApi: flowAPI,
        formApi,
        t,
      });
      const bi = flowAPI?.bi!;
      const biLogger =
        bi &&
        createFormBiLogger({
          viewerBiLogger: bi,
          initialState,
          wixSdkAdapter,
        });

      const formContext: FormContext = createFormContext({
        t,
        settings,
        wixSdkAdapter,
        formApi,
        biLogger,
      });

      const { render, actions } = await createControlledComponent<
        FormState,
        FormControllerActions,
        FormContext
      >({
        controllerConfig,
        initialState,
        actionsFactory: createFormActions,
        context: formContext,
      });
      rerender = render;

      wixSdkAdapter.onUserLogin(actions.onLogin);
      await wixSdkAdapter.renderSeoTags(
        ITEM_TYPES.BOOKINGS_FORM,
        await getSeoItemData(initialState, wixSdkAdapter, t),
      );
    },
    updateConfig($w, config) {
      const stateUpdate = mapConfigToState(config);
      rerender(stateUpdate);
    },
  };
};

export default createController;
