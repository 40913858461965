import {
  BulkRequest,
  BulkResponse,
  GetActiveFeaturesResponse,
  ServicesCatalogServer,
  GetServiceResponse,
  Rate,
} from '@wix/ambassador-services-catalog-server/http';
import {
  CalendarServer,
  ListSlotsResponse,
  ListSlotsRequest,
} from '@wix/ambassador-calendar-server/http';
import {
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import {
  BookingsServer,
  IsAvailableResponse,
} from '@wix/ambassador-bookings-server/http';
import { CheckoutServer } from '@wix/ambassador-checkout-server/http';
import { CouponsServer } from '@wix/ambassador-coupons-server/http';
import { ServiceType } from '@wix/bookings-uou-types';
import { mapCatalogResourceResponseToStaffMember } from '@wix/bookings-uou-mappers';
import {
  PaidPlans,
  Plan,
  Payments,
  CouponDetails,
} from '@wix/ambassador-checkout-server/types';
import { CatalogData } from './types';
import { Service } from '../utils/mappers/service.mapper';
import { createSessionFromSlotAvailability } from './platformAdaters';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { MembersNgApi } from '@wix/ambassador-members-ng-api/http';
import { mapBusinessResponseToBusinessInfo } from '../utils/mappers/businessInfo.mapper';
import { BusinessInfo } from '../types/types';
import { FormInfo } from '@wix/ambassador-bookings-server/types';
import { RateLabels } from '../utils/mappers/form-submission.mapper';
import { EmptyStateErrorType } from '../types/errors';

export const CATALOG_SERVER_URL = '_api/services-catalog';
export const BOOKINGS_SERVER_URL = '_api/bookings';
export const CALENDAR_SERVER_URL = '_api/calendar-server';
export const CHECKOUT_SERVER_URL = '_api/checkout-server';
export const MEMBERS_SERVER_API = '_api/members/v1/members';
export const COUPONS_SERVER_URL = '_api/coupons-server';

export class FormApi {
  private wixSdkAdapter: WixOOISDKAdapter;
  private catalogServer: ReturnType<typeof ServicesCatalogServer>;
  private bookingsServer: ReturnType<typeof BookingsServer>;
  private calendarServer: ReturnType<typeof CalendarServer>;
  private checkoutServer: ReturnType<typeof CheckoutServer>;
  private couponsServer: ReturnType<typeof CouponsServer>;
  private membersServer: ReturnType<typeof MembersNgApi>;

  constructor({ wixSdkAdapter }: { wixSdkAdapter: WixOOISDKAdapter }) {
    this.wixSdkAdapter = wixSdkAdapter;
    const baseUrl = wixSdkAdapter.getServerBaseUrl();
    this.catalogServer = ServicesCatalogServer(
      `${baseUrl}${CATALOG_SERVER_URL}`,
    );
    this.bookingsServer = BookingsServer(`${baseUrl}${BOOKINGS_SERVER_URL}`);
    this.calendarServer = CalendarServer(`${baseUrl}${CALENDAR_SERVER_URL}`);
    this.checkoutServer = CheckoutServer(`${baseUrl}${CHECKOUT_SERVER_URL}`);
    this.couponsServer = CouponsServer(`${baseUrl}${COUPONS_SERVER_URL}`);
    this.membersServer = MembersNgApi(`${baseUrl}${MEMBERS_SERVER_API}`, {
      ignoredProtoHttpUrlPart: '/v1/members',
    });
  }

  getAuthorization() {
    return this.wixSdkAdapter.getInstance();
  }

  async getCatalogData({
    serviceId,
    resourceId,
  }: {
    serviceId?: string;
    resourceId?: string;
  } = {}): Promise<CatalogData> {
    const servicesCatalogService = this.catalogServer.Bulk();
    const bulkRequest: BulkRequest = this.createBulkRequest({
      serviceId,
      resourceId,
    });
    const catalogData: BulkResponse = await servicesCatalogService({
      Authorization: this.getAuthorization(),
    }).get(bulkRequest);

    const service: GetServiceResponse = catalogData.responseService!;
    const businessInfo: BusinessInfo = mapBusinessResponseToBusinessInfo(
      catalogData.responseBusiness!,
    );

    const activeFeatures: GetActiveFeaturesResponse =
      catalogData.responseBusiness!.activeFeatures!;
    const staffMembers = catalogData.responseResources!.resources!.map(
      mapCatalogResourceResponseToStaffMember,
    );

    return {
      service,
      businessInfo,
      activeFeatures,
      staffMembers,
    };
  }

  async getSlots({
    firstSessionStart,
    lastSessionEnd,
    scheduleId,
  }: {
    firstSessionStart: string;
    lastSessionEnd: string;
    scheduleId: string;
  }): Promise<ListSlotsResponse> {
    try {
      const calendarService = this.calendarServer.CalendarService();
      // @ts-expect-error
      const fields: string[] = null;
      // @ts-expect-error
      const fieldsets: string[] = null;
      const filter = {
        from: new Date(firstSessionStart).toISOString(),
        to: new Date(lastSessionEnd).toISOString(),
        scheduleIds: [scheduleId],
      };
      const request: ListSlotsRequest = {
        query: {
          fieldsets,
          fields,
          sort: [],
          filter: JSON.stringify(filter),
        },
      };

      const calendarServiceResponse = calendarService({
        Authorization: this.getAuthorization(),
      });
      return calendarServiceResponse.listSlots(request);
    } catch (e) {
      throw EmptyStateErrorType.NO_LIST_SLOTS;
    }
  }

  async getMemberDetails(id: string): Promise<Optional<Member>> {
    if (this.wixSdkAdapter.isEditorMode()) {
      return;
    }
    const membersService = this.membersServer.Members();
    const { member } = await membersService({
      Authorization: this.getAuthorization(),
    }).getMember({
      fieldSet: 'FULL',
      id,
    });

    return member;
  }

  async getAvailability({
    scheduleId,
  }: {
    scheduleId: string;
  }): Promise<IsAvailableResponse> {
    try {
      const response = await this.bookingsServer
        .Availability()({ Authorization: this.getAuthorization() })
        .isAvailable({
          scheduleId,
          partySize: 1,
        });
      return response;
    } catch (e) {
      throw EmptyStateErrorType.NO_COURSE_AVAILABILITY;
    }
  }

  async getPricingPlanDetails({
    serviceId,
    startTime,
  }: {
    serviceId: string;
    startTime: string;
  }): Promise<Optional<PaidPlans>> {
    const response = await this.checkoutServer
      .CheckoutBackend()({ Authorization: this.getAuthorization() })
      .checkoutOptions({
        createSession: {
          scheduleOwnerId: serviceId,
          start: {
            timestamp: startTime,
          },
        },
        paymentSelection: {
          numberOfParticipants: 1,
        },
      });
    return response.checkoutOptions?.paidPlans;
  }

  async areCouponsAvailableForService(): Promise<boolean> {
    const { hasCoupons } = await this.couponsServer
      .CouponsV2()({ Authorization: this.getAuthorization() })
      .hasCoupons({});

    return hasCoupons;
  }

  async getPaymentsDetails(
    couponCode: string,
    slot: Slot,
    numberOfParticipants: number,
    rate: Rate,
    serviceId: string,
  ): Promise<Optional<Payments>> {
    const { scheduleId, startDate, endDate } = slot;
    const response = await this.checkoutServer
      .CheckoutBackend()({ Authorization: this.getAuthorization() })
      .checkoutOptions({
        scheduleId,
        couponCode,
        createSession: {
          rate,
          scheduleOwnerId: serviceId,

          start: {
            timestamp: startDate,
          },
          end: {
            timestamp: endDate,
          },
        },
        paymentSelection: {
          numberOfParticipants,
          rateLabel: RateLabels.GENERAL,
        },
      });
    return response.checkoutOptions?.payments;
  }

  async book({
    service,
    formInfo,
    slotAvailability,
    selectedPlan,
    sendSmsReminder,
    appliedCoupon,
  }: {
    service: Service;
    formInfo: FormInfo;
    slotAvailability?: SlotAvailability;
    selectedPlan?: Plan;
    sendSmsReminder?: boolean;
    appliedCoupon?: CouponDetails;
  }) {
    const serviceTypeSpecificPayload = this.serviceTypeDependentRequestPayload(
      service,
      slotAvailability,
    );

    const response = await this.bookingsServer
      .Bookings()({ Authorization: this.getAuthorization() })
      .book({
        ...serviceTypeSpecificPayload,
        formInfo,
        ...(appliedCoupon ? { couponCode: appliedCoupon.couponCode } : {}),
        planSelection: selectedPlan?.paidPlan,
        sendSmsReminder,
      });

    return response;
  }

  private serviceTypeDependentRequestPayload(
    service: Service,
    slotAvailability?: SlotAvailability,
  ) {
    switch (service.type) {
      case ServiceType.INDIVIDUAL:
        return {
          createSession: createSessionFromSlotAvailability(slotAvailability!),
        };
      case ServiceType.GROUP:
        return {
          bySessionId: {
            sessionId: slotAvailability!.slot!.sessionId,
          },
        };
      case ServiceType.COURSE:
        return {
          scheduleId: service.scheduleId,
        };
    }
  }

  private createBulkRequest({
    serviceId,
    resourceId,
  }: {
    serviceId?: string;
    resourceId?: string;
  }): BulkRequest {
    const filterByResourceType = {
      'resource.tags': { $hasSome: ['staff'] },
    };
    const filterById = {
      'resource.id': resourceId,
    };
    const filter = resourceId ? filterById : filterByResourceType;

    return {
      ...(serviceId
        ? {
            requestService: {
              id: serviceId,
              fields: [],
            },
          }
        : {}),
      requestBusiness: {
        suppressNotFoundError: false,
      },
      requestListResources: {
        includeDeleted: true,
        query: {
          filter: JSON.stringify(filter),
          fields: ['resource.id', 'resource.name'],
          fieldsets: [],
          paging: {
            limit: 1000,
          },
          sort: [],
        },
      },
    };
  }
}
