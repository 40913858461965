import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
  getDefaultPaymentOptionId,
} from '../../../../utils/state/initialStateFactory';
import { FormContext } from '../../../../utils/context/contextFactory';
import { IUser } from '@wix/native-components-infra/dist/src/types/types';
import { setFieldsValuesInStorage } from '../../../../utils/storageFunctions';

export type OnLogin = (user?: IUser) => Promise<void>;

export function createOnLoginAction({
  getControllerState,
  context: { formApi, settings, wixSdkAdapter },
}: ActionFactoryParams<FormState, FormContext>): OnLogin {
  return async (user) => {
    const [state, setState] = getControllerState();
    const { slotAvailability } = state;
    const { serviceId, startDate } = slotAvailability.slot!;
    setFieldsValuesInStorage(wixSdkAdapter, {
      state: {
        valid: false,
        errors: {},
      },
      submission: {},
    });

    if (user) {
      try {
        const memberDetails = await formApi.getMemberDetails(user.id);
        const pricingPlanDetails = await formApi.getPricingPlanDetails({
          serviceId: serviceId!,
          startTime: startDate!,
        });
        const selectedPaymentOptionId = getDefaultPaymentOptionId(
          settings,
          state.service.payment,
          pricingPlanDetails,
        );

        setState({
          memberDetails,
          pricingPlanDetails,
          selectedPaymentOptionId,
          status: FormStatus.IDLE,
          overideDefaultFieldsValues: true,
        });
      } catch (e) {
        setState({ status: FormStatus.IDLE });
      }
    }
  };
}
