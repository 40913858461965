import {
  createSetNumberOfParticipantsAction,
  SetNumberOfParticipants,
} from './setNumberOfParticipants/setNumberOfParticipants';
import { FormState } from '../../../utils/state/initialStateFactory';
import { FormContext } from '../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../utils/ControlledComponent/ControlledComponent.types';
import { createOnSubmitAction, OnSubmit } from './onSubmit/onSubmit';
import {
  createSetPaymentOptionAction,
  SetPaymentOption,
} from './setPaymentOption/setPaymentOption';
import {
  createPromptLoginAction,
  PromptLogin,
} from './promptLogin/promptLogin';
import { createSetCouponAction, SetCoupon } from './setCoupon/setCoupon';
import {
  createRemoveCouponAction,
  RemoveCoupon,
} from './removeCoupon/removeCoupon';
import {
  createOnToggleCouponAction,
  OnToggleCoupon,
} from './onToggleCoupon/onToggleCoupon';
import { createOnLoginAction, OnLogin } from './onLogin/onLogin';
import {
  OnToastClose,
  createOnToastCloseAction,
} from './onToastClose/onToastClose';
import {
  createInitializeWidgetAction,
  InitializeWidget,
} from './initializeWidget/initializeWidget';
import { createOnLogoutAction, OnLogout } from './onLogout/onLogout';
import { createFormErrorHandlers } from './errorHandlers/errorHandlers';

export type FormControllerActions = {
  onSubmit: OnSubmit;
  setCoupon: SetCoupon;
  removeCoupon: RemoveCoupon;
  setPaymentOption: SetPaymentOption;
  onLogin: OnLogin;
  promptLogin: PromptLogin;
  setNumberOfParticipants: SetNumberOfParticipants;
  onToggleCoupon: OnToggleCoupon;
  onToastClose: OnToastClose;
  initializeWidget: InitializeWidget;
  onLogout: OnLogout;
};
export const createFormActions = (
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
): FormControllerActions => {
  const errorHandlers = createFormErrorHandlers(actionFactoryParams);
  return {
    onSubmit: createOnSubmitAction(actionFactoryParams, errorHandlers),
    setPaymentOption: createSetPaymentOptionAction(actionFactoryParams),
    promptLogin: createPromptLoginAction(actionFactoryParams),
    onLogin: createOnLoginAction(actionFactoryParams),
    setCoupon: createSetCouponAction(actionFactoryParams, errorHandlers),
    removeCoupon: createRemoveCouponAction(actionFactoryParams, errorHandlers),
    setNumberOfParticipants:
      createSetNumberOfParticipantsAction(actionFactoryParams),
    onToggleCoupon: createOnToggleCouponAction(actionFactoryParams),
    onToastClose: createOnToastCloseAction(actionFactoryParams, errorHandlers),
    initializeWidget: createInitializeWidgetAction(actionFactoryParams),
    onLogout: createOnLogoutAction(actionFactoryParams),
  };
};
