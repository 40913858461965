import { GenericErrorType } from '../../../../types/errors';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';

export type OnToastClose = () => void;

export function createOnToastCloseAction(
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
  errorHandlers: ErrorHandlers,
): OnToastClose {
  return () => {
    errorHandlers.clearErrorByTypes([GenericErrorType]);
  };
}
