import { PaidPlans } from '@wix/ambassador-checkout-server/types';
import { Member } from '@wix/ambassador-members-ng-api/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import settingsParams from '../../components/BookingsForm/settingsParams';
import {
  BusinessInfo,
  PaymentMethod,
  ReservedPaymentOptionIds,
  SettingsSubTab,
  SettingsTab,
  TFunction,
} from '../../types/types';
import {
  getActiveSchedule,
  getServiceType,
  mapCatalogServiceToService,
  Service,
} from '../mappers/service.mapper';
import { ServicePayment, ServiceType } from '@wix/bookings-uou-types';
import { GetActiveFeaturesResponse } from '@wix/ambassador-services-catalog-server/types';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import { FormApi } from '../../api/FormApi';
import { IWidgetControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { createDummyState } from '../dummies/dummy-data';
import { isOfferedAsOneTime, isOfferedAsPricingPlan } from '../payment/payment';
import { Submission } from '@wix/forms-ui/dist/types/types';
import { getSessionValues } from '../storageFunctions';
import {
  FormErrors,
  EmptyStateErrorType,
  GenericErrorType,
} from '../../types/errors';
import { getErrorByType } from '../errors/errors';
import { CouponInfo } from '../../types/coupons';

export type EditorContext = {
  isDummy: boolean;
  selectedSettingsTabId?: SettingsTab;
  selectedSettingsSubTabId?: SettingsSubTab;
};
export enum FormStatus {
  IDLE = 'idle',
  SSR = 'ssr',
  PROCESSING_BOOK_REQUEST = 'processing-book-request',
  PROCESSING_USER_DETAILS = 'processing-user-details',
  INITIALIZING = 'initializing',
}

export type FormState = {
  service: Service;
  isPricingPlanInstalled: boolean;
  couponInfo: CouponInfo;
  businessInfo: BusinessInfo;
  activeFeatures: GetActiveFeaturesResponse;
  slotAvailability: SlotAvailability;
  numberOfParticipants: number;
  pricingPlanDetails?: PaidPlans;
  memberDetails?: Member;
  errors: FormErrors[];
  selectedPaymentOptionId: string;
  editorContext: EditorContext;
  status: FormStatus;
  overideDefaultFieldsValues?: boolean;
};

export async function createInitialState({
  t,
  flowApi,
  wixSdkAdapter,
  formApi,
}: {
  t: TFunction;
  flowApi: ControllerFlowAPI;
  wixSdkAdapter: WixOOISDKAdapter;
  formApi: FormApi;
}): Promise<FormState> {
  const { settings, controllerConfig } = flowApi;

  const serviceId = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.SERVICE_ID,
  );

  if (wixSdkAdapter.isSSR()) {
    return {
      status: FormStatus.SSR,
    } as FormState;
  }

  if (
    wixSdkAdapter.isEditorMode() ||
    (wixSdkAdapter.isPreviewMode() && !serviceId)
  ) {
    const [catalogData, isPricingPlanInstalled] = await Promise.all([
      formApi.getCatalogData(),
      wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
    ]);
    return createDummyState(
      flowApi,
      catalogData.businessInfo,
      isPricingPlanInstalled,
    );
  }

  try {
    const {
      areCouponsAvailable,
      catalogData,
      listSlots,
      memberDetails,
      slotAvailability,
      pricingPlanDetails,
      isPricingPlanInstalled,
      errors,
    } = await fetchInitialData({
      formApi,
      controllerConfig,
      wixSdkAdapter,
      serviceId,
    });

    const emptyStateError = getErrorByType({
      errorType: EmptyStateErrorType,
      errors,
    });

    if (emptyStateError) {
      throw emptyStateError;
    }

    const preFilledValues: Submission | undefined = getSessionValues(
      wixSdkAdapter,
      BookingsQueryParams.FILLED_FIELDS,
    );

    const service = mapCatalogServiceToService({
      catalogData: catalogData!,
      slotAvailability: slotAvailability!,
      pricingPlanDetails,
      preFilledValues,
      t,
      listSlots,
      serviceId,
    });

    const selectedPaymentOptionId = getDefaultPaymentOptionId(
      settings,
      service.payment,
      pricingPlanDetails,
    );

    return {
      activeFeatures: catalogData!.activeFeatures,
      service,
      businessInfo: catalogData!.businessInfo,
      slotAvailability: slotAvailability!,
      isPricingPlanInstalled: isPricingPlanInstalled!,
      pricingPlanDetails,
      memberDetails,
      numberOfParticipants: 1,
      errors,
      selectedPaymentOptionId,
      couponInfo: {
        areCouponsAvailable,
        isCouponInputDisplayed: false,
      },
      editorContext: {
        isDummy: false,
      },
      status: FormStatus.INITIALIZING,
      overideDefaultFieldsValues: false,
    };
  } catch (formError) {
    return {
      errors: [formError],
    } as FormState;
  }
}

export const getDefaultPaymentOptionId = (
  settings: ControllerFlowAPI['settings'],
  servicePayment: ServicePayment,
  pricingPlanDetails?: PaidPlans,
) => {
  const planId = pricingPlanDetails?.defaultPlan?.paidPlan?.orderId;
  if (planId) {
    return planId;
  } else if (
    isOfferedAsOneTime(servicePayment) &&
    !isOfferedAsPricingPlan(servicePayment)
  ) {
    return ReservedPaymentOptionIds.SingleSession;
  } else {
    return settings.get(settingsParams.defaultPaymentMethod) ===
      PaymentMethod.SINGLE
      ? ReservedPaymentOptionIds.SingleSession
      : ReservedPaymentOptionIds.BuyAPricingPlan;
  }
};

const fetchInitialData = async ({
  formApi,
  controllerConfig,
  wixSdkAdapter,
  serviceId,
}: {
  formApi: FormApi;
  controllerConfig: IWidgetControllerConfig;
  wixSdkAdapter: WixOOISDKAdapter;
  serviceId?: string;
}) => {
  let errors: FormErrors[] = [];

  if (!serviceId) {
    throw EmptyStateErrorType.INVALID_SERVICE_ID;
  }

  let slotAvailability = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.AVAILABILITY_SLOT,
  );
  const resourceId = slotAvailability?.slot!.resource!.id!;
  const startTime = slotAvailability?.slot!.startDate!;
  const user = controllerConfig.wixCodeApi.user.currentUser;
  const isLoggedInUser = user.loggedIn;
  const shouldGetPricingPlanDetails = isLoggedInUser && startTime;

  const [
    areCouponsAvailable,
    catalogData,
    memberDetails,
    pricingPlanDetails,
    isPricingPlanInstalled,
  ] = await Promise.all([
    formApi.areCouponsAvailableForService().catch(() => false),
    formApi.getCatalogData({ serviceId, resourceId }).catch(() => undefined),
    isLoggedInUser
      ? formApi.getMemberDetails(user.id).catch((e) => {
          errors = [...errors, GenericErrorType.GENERIC_MEMBER_DETAILS_ERROR];
          return undefined;
        })
      : undefined,
    shouldGetPricingPlanDetails
      ? formApi
          .getPricingPlanDetails({
            serviceId,
            startTime,
          })
          .catch((e) => {
            errors = [...errors, GenericErrorType.GENERIC_PRICING_PLAN_ERROR];
            return undefined;
          })
      : undefined,
    wixSdkAdapter.isPricingPlanInstalled().catch(() => false),
  ]);

  const activeSchedule = getActiveSchedule(catalogData?.service!);
  const scheduleId = activeSchedule?.id!;
  const firstSessionStart = activeSchedule?.firstSessionStart;
  const lastSessionEnd = activeSchedule?.lastSessionEnd;

  const isCatalogDataValid = catalogData && activeSchedule;
  if (!isCatalogDataValid) {
    throw EmptyStateErrorType.INVALID_CATALOG_DATA;
  }

  const type = getServiceType(activeSchedule);
  const isCourse = type === ServiceType.COURSE;

  const getListSlots = () =>
    isCourse && firstSessionStart && lastSessionEnd
      ? formApi.getSlots({
          firstSessionStart,
          lastSessionEnd,
          scheduleId,
        })
      : {};

  if (isCourse && !firstSessionStart) {
    throw EmptyStateErrorType.COURSE_WITHOUT_SESSIONS;
  }

  const [listSlots, courseAvailability] = await Promise.all([
    getListSlots(),
    isCourse
      ? formApi.getAvailability({ scheduleId }).catch((error) => {
          throw error;
        })
      : {},
  ]);

  if (isCourse && courseAvailability) {
    slotAvailability = {
      openSpots:
        Number(courseAvailability.capacity) -
        Number(courseAvailability.totalNumberOfParticipants),
      slot: {
        startDate: firstSessionStart,
        endDate: lastSessionEnd,
      },
    };
  }

  const isSlotAvailabilityValid = isCourse || slotAvailability?.slot;
  if (!isSlotAvailabilityValid) {
    throw EmptyStateErrorType.INVALID_SLOT_AVAILABILITY;
  }

  return {
    catalogData,
    listSlots,
    memberDetails,
    slotAvailability,
    pricingPlanDetails,
    isPricingPlanInstalled,
    errors,
    areCouponsAvailable,
  };
};
