import { CouponsErrorType, GenericErrorType } from '../../../../types/errors';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { mapCouponServerError } from '../../../../utils/errors/errors';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';
import { getCouponInfoWithoutAppliedCoupon } from '../removeCoupon/removeCoupon';

export type SetCoupon = (couponCode: string) => void;

export function createSetCouponAction(
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
  errorHandlers: ErrorHandlers,
): SetCoupon {
  return async (couponCode: string) => {
    const [state, setState] = actionFactoryParams.getControllerState();
    const { formApi } = actionFactoryParams.context;
    const { numberOfParticipants, slotAvailability, service } = state;
    const slot = slotAvailability?.slot!;
    const rate = service.rate;
    const serviceId = service.id;
    if (couponCode) {
      try {
        const getPaymentsDetails = await formApi.getPaymentsDetails(
          couponCode,
          slot,
          numberOfParticipants,
          rate,
          serviceId,
        );
        const appliedCoupon = getPaymentsDetails?.couponDetails;

        errorHandlers.clearErrorByTypes([CouponsErrorType, GenericErrorType]);

        setState({
          couponInfo: {
            ...getCouponInfoWithoutAppliedCoupon(state.couponInfo),
            appliedCoupon,
          },
        });
      } catch (error) {
        const errorResponse = JSON.stringify(error);
        const couponError = mapCouponServerError(errorResponse);

        setState({
          couponInfo: getCouponInfoWithoutAppliedCoupon(state.couponInfo),
        });
        if (couponCode) {
          errorHandlers.addError(couponError);
        }
      }
    } else {
      errorHandlers.clearErrorByTypes([CouponsErrorType, GenericErrorType]);
      setState({
        couponInfo: getCouponInfoWithoutAppliedCoupon(state.couponInfo),
      });
    }
  };
}
