import { CouponsErrorType, FormErrors } from '../../types/errors';

export const getErrorByType = <ErrorType>({
  errorType,
  errors,
}: {
  errorType: ErrorType;
  errors: FormErrors[];
}): ErrorType[keyof ErrorType] => {
  return Object.values(errorType).find((error) =>
    errors.includes(error as FormErrors),
  ) as ErrorType[keyof ErrorType];
};

export const hasErrorOfType = <ErrorType>({
  errorType,
  errors,
}: {
  errorType: ErrorType;
  errors: FormErrors[];
}): boolean => {
  return Object.values(errorType).some((error) =>
    errors.includes(error as FormErrors),
  );
};

export function mapCouponServerError(
  couponServerError: string,
): CouponsErrorType {
  if (couponServerError.includes('id is not a valid GUID')) {
    return CouponsErrorType.NOT_VALID_FOR_SELECTED_SERVICE;
  } else if (couponServerError.includes('ERROR_COUPON_HAS_EXPIRED')) {
    return CouponsErrorType.COUPON_HAS_EXPIRED;
  } else if (couponServerError.includes('ERROR_COUPON_USAGE_EXCEEDED')) {
    return CouponsErrorType.COUPON_USAGE_EXCEEDED;
  } else if (couponServerError.includes('ERROR_COUPON_SERVICE_UNAVAILABLE')) {
    return CouponsErrorType.COUPON_SERVICE_UNAVAILABLE;
  } else if (couponServerError.includes('ERROR_COUPON_DOES_NOT_EXIST')) {
    return CouponsErrorType.COUPON_DOES_NOT_EXIST;
  }
  return CouponsErrorType.GENERAL_SERVER_ERROR;
}
