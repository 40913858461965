import { CouponsErrorType } from '../../../../types/errors';
import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { FormState } from '../../../../utils/state/initialStateFactory';
import { CouponInfo } from '../../../../types/coupons';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';

export type RemoveCoupon = () => void;

export function createRemoveCouponAction(
  actionFactoryParams: ActionFactoryParams<FormState, FormContext>,
  errorHandlers: ErrorHandlers,
): RemoveCoupon {
  return async () => {
    const [state, setState] = actionFactoryParams.getControllerState();

    errorHandlers.clearErrorByTypes([CouponsErrorType]);

    setState({
      couponInfo: getCouponInfoWithoutAppliedCoupon(state.couponInfo),
    });
  };
}

export function getCouponInfoWithoutAppliedCoupon(couponInfo: CouponInfo) {
  return {
    areCouponsAvailable: couponInfo.areCouponsAvailable,
    isCouponInputDisplayed: couponInfo.isCouponInputDisplayed,
  };
}
