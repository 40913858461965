import { FormContext } from '../../../../utils/context/contextFactory';
import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { SubmissionResponse } from '@wix/forms-ui/types';
import { widgetDefaults } from '../../../../utils/bi/consts';
import { mapSubmissionToPartialBookRequest } from '../../../../utils/mappers/form-submission.mapper';
import { ReservedPaymentOptionIds } from '../../../../types/types';
import {
  BookingsQueryParams,
  WixOOISDKAdapter,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { Service } from '../../../../utils/mappers/service.mapper';
import { SlotAvailability } from '@wix/ambassador-availability-calendar/types';
import {
  BookingsErrorCodes,
  GenericErrorType,
  mappedNotificationErrorCodesToErrorTypes,
} from '../../../../types/errors';
import {
  getSessionValues,
  setFieldsValuesInStorage,
} from '../../../../utils/storageFunctions';
import { ErrorHandlers } from '../errorHandlers/errorHandlers';

export type OnSubmit = (submission: SubmissionResponse) => void;

export function createOnSubmitAction(
  {
    context: { wixSdkAdapter, formApi },
    getControllerState,
  }: ActionFactoryParams<FormState, FormContext>,
  errorHandlers: ErrorHandlers,
): OnSubmit {
  return async (submissionResponse) => {
    const [state, setState] = getControllerState();
    const {
      service,
      businessInfo,
      slotAvailability,
      selectedPaymentOptionId,
      pricingPlanDetails,
      couponInfo,
      status,
    } = state;

    if (status !== FormStatus.IDLE) {
      return;
    }

    if (selectedPaymentOptionId === ReservedPaymentOptionIds.BuyAPricingPlan) {
      setFieldsValuesInStorage(wixSdkAdapter, submissionResponse);
      return purchasePricingPlan(service, wixSdkAdapter, slotAvailability);
    }

    if (submissionResponse.state.valid) {
      setState({
        status: FormStatus.PROCESSING_BOOK_REQUEST,
      });

      if (wixSdkAdapter.isPreviewMode()) {
        return wixSdkAdapter.navigateToBookingsWithSuffix();
      }
      setFieldsValuesInStorage(wixSdkAdapter, submissionResponse);
      const { formInfo, sendSmsReminder } = mapSubmissionToPartialBookRequest(
        submissionResponse.submission,
        service,
        businessInfo,
      );
      const selectedPlan = pricingPlanDetails?.plans?.find(
        (plan) => plan?.paidPlan?.orderId === selectedPaymentOptionId,
      );
      try {
        const bookingResponse = await formApi.book({
          service,
          formInfo,
          slotAvailability,
          selectedPlan,
          sendSmsReminder,
          appliedCoupon: couponInfo.appliedCoupon,
        });
        return wixSdkAdapter.navigateToBookingsCheckout(
          bookingResponse.booking!,
          widgetDefaults.pageName,
        );
      } catch (e) {
        const serverErrorCode = e?.response?.details?.applicationError?.code;
        if (serverErrorCode) {
          const error =
            mappedNotificationErrorCodesToErrorTypes[
              serverErrorCode as BookingsErrorCodes
            ];
          errorHandlers.addError(error);
        } else {
          errorHandlers.addError(GenericErrorType.GENERIC_BOOK_ERROR);
        }
        setState({ status: FormStatus.IDLE });
      }
    }
  };
}

function purchasePricingPlan(
  service: Service,
  wixSdkAdapter: WixOOISDKAdapter,
  slotAvailability: SlotAvailability,
) {
  const planIds = service.payment.pricingPlanInfo?.pricingPlans.map(
    (plan) => plan.id,
  );
  const { referral } = getQueryParams(wixSdkAdapter);
  const timezone = getSessionValues(
    wixSdkAdapter,
    BookingsQueryParams.TIMEZONE,
  );

  return wixSdkAdapter.navigateToPricingPlan({
    redirectTo: { sectionId: 'Booking Form', relativePath: '' },
    planIds: planIds!,
    maxStartDate: slotAvailability.slot?.startDate!,
    queryParams: {
      referral,
      timezone,
      service: service.id,
    },
  });
}

export function getQueryParams(wixSdkAdapter: WixOOISDKAdapter): {
  [key: string]: string;
} {
  const referral = wixSdkAdapter.getUrlQueryParamValue(
    BookingsQueryParams.REFERRAL,
  );
  return { referral };
}
