import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import {
  FormState,
  FormStatus,
} from '../../../../utils/state/initialStateFactory';
import { FormContext } from '../../../../utils/context/contextFactory';

export type PromptLogin = () => void;

export function createPromptLoginAction({
  getControllerState,
  context: { wixSdkAdapter },
}: ActionFactoryParams<FormState, FormContext>): PromptLogin {
  return () => {
    const [, setState] = getControllerState();
    setState({
      status: FormStatus.PROCESSING_USER_DETAILS,
    });
    return wixSdkAdapter.promptUserLogin();
  };
}
